import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

export default function InvoicesFilter({ value, values, onChange }: any) {
  return (
    <div>
      <FormControl sx={{ width: '100%' }} size='small'>
        <Select
          className='axtel-text'
          labelId='demo-select-small-label'
          id='demo-select-small'
          value={value}
          defaultValue={3}
          label='Age'
          onChange={onChange}
        >
          {values?.map((item: any, index: number) => (
            <MenuItem value={item?.value}>{item?.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
