import { useContext } from 'react'
import { PaymentContext } from 'context/Payment/Context'

const usePaymentContext = (): any => {
  const {
    globalState: { customer, invoices, subscription, charges },
    setCustomerData,
    setInvoices,
    setSubscription,
    setCharges
  } = useContext(PaymentContext)
  return {
    customer,
    invoices,
    subscription,
    charges,
    setCustomerData,
    setInvoices,
    setSubscription,
    setCharges
  }
}

export default usePaymentContext
