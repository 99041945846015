/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from 'hooks/usePrivateAxios'
import useUserData from 'hooks/useUserData'
import { urls } from 'utils/urls'

const useLogout = () => {
  const privateAxios = useAxiosPrivate()
  const {
    setGlobalLoading,
    setUserData,
    setInternetScore,
    setNetworkData,
    setNetworkDevices
  } = useUserData()

  const navigate = useNavigate()

  useEffect(() => {
    handleLogout()
    navigate('/login')
  }, [])

  const handleLogout = async () => {
    try {
      await privateAxios.post(urls.customerLogout)
      setUserData({})
      setUserData({})
      setNetworkData({})
      setInternetScore({})
      setNetworkDevices([])
    } catch (error) {
    } finally {
      setGlobalLoading(false)
    }
  }

  return { handleLogout }
}

export default useLogout
