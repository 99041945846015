import { urls } from 'utils/urls'
import React from 'react'
import { privateAxiosProducts } from 'utils/axios'

const useProducts = () => {
  const [products, setProducts] = React.useState([])

  const getProducts = async (params: any) => {
    try {
      const { data: response } = await privateAxiosProducts.get(
        urls.getProducts,
        {
          params: params
        }
      )
      setProducts(response)
      return response
    } catch (error) {
      //notify('error', 'No se puede descargar la factura')
      return []
    } finally {
    }
  }

  return {
    products,
    getProducts
  }
}

export default useProducts
