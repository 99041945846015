import React from 'react'
import { Grid, Card, CardContent, Typography, Chip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { invoicesLimitValues } from 'utils/constants'
import InvoicesFilter from './InvoicesFilter'
import {
  transformTimestampToDate,
  transformTimestampToDateFilename
} from 'utils/time'
import useBilling from 'hooks/useBilling'
import useProducts from 'hooks/useProducts'
import DownloadIcon from '@mui/icons-material/Download'
import LoadingButton from '@mui/lab/LoadingButton'
import InvoiceModal from './InvoiceModal'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export default function Invoices({ invoices, refreshInvoices }: any) {
  const { getCFDI } = useBilling()
  const { getProducts } = useProducts()

  const [loadingInvoice, setLoadingInvoice] = React.useState<any>({})
  const [openInvoice, setOpenInvoice] = React.useState<any>({})
  const [openModal, setOpenModal] = React.useState(false)

  const setInvoiceTransformed = async (invoice: any) => {
    let lines: any[] = []
    let ids: string[] = []

    invoice.lines.data.forEach((item: any) => {
      ids.push(item.price.product)
    })

    await getProducts({ products: ids.join(',') }).then((response) => {
      invoice.lines.data.forEach((item: any) => {
        let name = item.description
        let dis = 0

        if (response.length > 0) {
          let pr = response.find(
            (it: any) => it.productId === item.price.product
          )

          if (pr.name) name = `${item.quantity} - ${pr.name}`

          if (item.discount_amounts) {
            if (item.discount_amounts[0]) {
              dis = item.discount_amounts[0].amount
            }
          }

          lines.push({
            id: item.id,
            name: name,
            amount: item.amount,
            productId: item.price.product,
            discount: dis
          })

          let data = {
            id: invoice.id,
            date: new Date(invoice.periodEnd * 1000).toLocaleDateString(),
            status: invoice.status,
            paymentMethodTypes:
              invoice?.paymentIntent?.payment_method_types ?? [],
            lines: lines,
            paid: invoice.paid,
            subtotal: invoice.amountDue,
            discountName: invoice.discount?.coupon?.name ?? ''
          }

          setOpenInvoice(data)
          setOpenModal(true)
        }
      })
    })
  }

  const handleDownload = async (invoice: any) => {
    setLoadingInvoice(invoice)
    await getCFDI(invoice.id).then((response) => {
      if (response?.data?.pdf) {
        var zipFile = new JSZip()

        let date = transformTimestampToDateFilename(invoice?.periodEnd)
        zipFile.file(
          `xtremo_factura_${date}.pdf`,
          response?.data?.pdf?.Content,
          { base64: true }
        )
        zipFile.file(
          `xtremo_factura_${date}.xml`,
          response?.data?.xml?.Content,
          { base64: true }
        )

        zipFile.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `xtremo_factura_${date}.zip`)
        })
        /**
        const linkSource = `data:application/pdf;base64,${response?.data?.pdf?.Content}`
        const downloadLink = document.createElement('a')
        const fileName = `xtremo_factura_${transformTimestampToDateFilename(
          invoice?.periodEnd
        )}.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
         */
      }
      setLoadingInvoice({})
    })
  }

  return (
    <div>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} lg={6}>
          <Card style={{ margin: 5 }} variant='outlined'>
            <CardContent>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant='caption' className='axtel-text'>
                    Historial de facturas
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InvoicesFilter
                    values={invoicesLimitValues}
                    onChange={(e: any) => {
                      refreshInvoices(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className='serviceScoreContent-speedtests'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TableContainer className='speedtests-tableContainer'>
                          <Table
                            className='serviceScore-speedtests-table'
                            stickyHeader
                            size='small'
                            aria-label='a dense table'
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant='caption'
                                    className='axtel-text'
                                  >
                                    Fecha
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant='caption'
                                    className='axtel-text'
                                  >
                                    Estatus
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  <Typography variant='caption'>
                                    Descargar
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant='caption'
                                    className='axtel-text'
                                  >
                                    Monto
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                  <Typography variant='caption'>
                                    Método de pago
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoices?.map((invoice: any, index: number) => {
                                return (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0
                                      }
                                    }}
                                    onClick={() => {
                                      setInvoiceTransformed(invoice)
                                    }}
                                  >
                                    <TableCell>
                                      <Typography
                                        variant='subtitle2'
                                        className='axtel-text'
                                      >
                                        {transformTimestampToDate(
                                          invoice?.periodEnd
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Chip
                                        label={
                                          invoice?.paid ? 'pagada' : 'pendiente'
                                        }
                                        sx={{
                                          backgroundColor: invoice?.paid
                                            ? '#39C7A5'
                                            : '#EA4870',
                                          color: 'white'
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                      <LoadingButton
                                        onClick={() => {
                                          handleDownload(invoice)
                                        }}
                                        disabled={loadingInvoice?.id}
                                        loading={
                                          loadingInvoice?.id == invoice?.id
                                        }
                                      >
                                        <DownloadIcon />
                                      </LoadingButton>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant='subtitle2'
                                        className='axtel-text'
                                      >
                                        $
                                        {(
                                          invoice?.amountPaid / 100 ||
                                          invoice.amountDue / 100
                                        ).toLocaleString()}
                                      </Typography>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                      {invoice?.paymentIntent?.payment_method_types?.includes(
                                        'customer_balance'
                                      ) ? (
                                        <Typography
                                          variant='subtitle2'
                                          className='axtel-text'
                                        >
                                          Transferencia bancaria
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant='subtitle2'
                                          className='axtel-text'
                                        >
                                          {
                                            invoice?.paymentIntent
                                              ?.payment_method?.card?.last4
                                          }
                                        </Typography>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {openInvoice?.id && (
                          <InvoiceModal
                            invoice={openInvoice}
                            isOpen={openModal}
                            handleClose={() => {
                              setOpenInvoice({})
                              setOpenModal(false)
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
