/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Grid, Stack, Card, CardContent, Typography } from '@mui/material'
import whatsappIcon from 'assets/images/whatsapp.png'
import useUserData from 'hooks/useUserData'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import { motion } from 'framer-motion'

//import useGoogleTagManager from 'hooks/useGoogleTagManager'

const ReportProblem = ({ warningMessage: string = '' }) => {
  const { networkData, isGlobalLoading, setGlobalLoading } = useUserData()
  const { newEvent } = useGoogleAnalytics()
  //const { newEvent } = useGoogleTagManager()
  useEffect(() => {
    isGlobalLoading && setGlobalLoading(false)
  }, [])
  const handleClickWhatsapp = () => {
    newEvent('action_buttons', 'whatsapp_help')
    window.open('https://wa.me/' + process.env.REACT_APP_WA_NUMBER)
  }
  return (
    <Card variant='outlined'>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Typography variant='h6' className='axtel-text'>
              Ayuda o Reportar un Problema
            </Typography>
            <Typography variant='subtitle2' className='axtel-text'>
              Comunicate con nosotros a traves de:
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <div>
              <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                <Stack
                  direction='row'
                  spacing={1}
                  justifyContent='center'
                  alignItems='center'
                  onClick={handleClickWhatsapp}
                >
                  <img alt='' src={whatsappIcon}></img>
                  <h1 className='reportProblem-whatsapp'>Whatsapp</h1>
                </Stack>
              </motion.div>
            </div>
          </Grid>

          {(networkData?.health?.internet_status === 'error' ||
            networkData?.health?.internet_status === 'disconnected') && (
            <Grid item xs={12}>
              <h3 className='reportProblem-warning'>
                Desconectate de la red WiFi y utiliza tus datos móviles
              </h3>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ReportProblem
