export const transformTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  return date.toLocaleDateString()
}

export const transformTimestampToDateFilename = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  let stringDate = date.toLocaleDateString()

  return stringDate.split('/').join('-')
}

export const toLocalTZ = (date: any): any => {
  let localDate = new Date(date + 'UTC')
  return localDate.toString() === 'Invalid Date' ? date : localDate
}

export const formatUnixTimestampString = (unixTimestamp: any) => {
  const date = new Date(unixTimestamp * 1000) // Convert to milliseconds by multiplying by 1000

  let meses = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ]
  let diasSemana = [
    'domingo',
    'lunes',
    'martes',
    'miércoles',
    'jueves',
    'viernes',
    'sábado'
  ]

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based in JavaScript
  const year = date.getFullYear()

  return `${diasSemana[date.getDay()]}, ${day} de ${
    meses[Number(month) - 1]
  } de ${year}`
}
