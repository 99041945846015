import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import BillingDataForm from './BillingDataForm'
import useBilling from 'hooks/useBilling'

//utils
import { notify } from 'utils/notify'
import { validateBillingProfile } from 'utils/validations/billing'

//Icons
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { isEmpty, update } from 'lodash'

export default function BillingDataPage() {
  const {
    billingCustomer,
    createCustomer,
    validateFiscalData,
    updateBillingCustomer
  } = useBilling()
  const navigate = useNavigate()
  const environ = process.env.REACT_APP_ENV
  const validateCustomerProfile = async (customer: any) => {
    let isValid = false
    const payload = {
      rfc: customer.rfc,
      name: customer.name,
      zipCode: customer?.address?.zipCode,
      fiscalRegime: customer.fiscalRegime
    }
    const validation = await validateFiscalData(payload)

    if (validation) {
      isValid = await validateBillingProfile(validation)
    }

    if (isValid || environ === 'dev') {
      if (isEmpty(billingCustomer)) {
        const billing = await createCustomer(customer)
        if (billing) {
          /* navigate('payments') */
        }
      } else {
        updateBillingCustomer(customer)
      }
    } else {
      notify('error', 'Datos fiscales inválidos')
    }
  }

  return (
    <div className='supportContent'>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          item
          container
          xs={12}
          justifyContent='left'
          alignItems='center'
          style={{ margin: 5 }}
        >
          <IconButton
            className='navigationButton axtel-icon-rounded'
            //color='primary'

            aria-label='upload picture'
            component='label'
            onClick={() => navigate(-1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <BillingDataForm
            billingCustomer={billingCustomer}
            submitForm={validateCustomerProfile}
          />
        </Grid>
      </Grid>

      <Grid container></Grid>
    </div>
  )
}
