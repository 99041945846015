import React from 'react'
import { Dialog, DialogContent, Card, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getSessionItem } from 'utils/session'
import PaymentForm from './PaymentMethodForm'
import usePaymentMethod from 'hooks/usePaymentMethod'
import usePaymentData from 'hooks/usePaymentData'

export default function UpdatePaymentMethodDialog({
  open,
  onClose,
  setOpen,
  customer
}: any) {
  const { getCustomer } = usePaymentData()
  const { updateDefaultPaymentMethod } = usePaymentMethod()
  const handleSavePaymentMethod = async (paymentMethod: any) => {
    const data = await updateDefaultPaymentMethod(customer?.id, paymentMethod)
    if (data) {
      getCustomer()
      setOpen(false)
    }
  }
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography variant='h6' className='axtel-text'>
            Ingresa tu nuevo método de pago
          </Typography>
          <br />
          <Typography variant='caption' className='axtel-text'>
            Ingresa tu tarjeta crédito o débito en la que haremos los cargos
            mensuales de tu servicio
          </Typography>
          <br />
          <br />
          {getSessionItem('stripeClientSecret') && (
            <Elements
              stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY || '')}
              options={{
                clientSecret: getSessionItem('stripeClientSecret') || '',
                appearance: {
                  variables: { colorPrimaryText: '#262626' }
                }
              }}
            >
              <PaymentForm savePaymentmethod={handleSavePaymentMethod} />
            </Elements>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
