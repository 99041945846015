import { useContext } from 'react'
import { Context } from 'context/Context'
import { TspeedTest, TnetworkDevice, TconnectedDevice } from 'types/types'

type TuseUserData = {
  userData: any
  internetScore: any
  networkData: any
  networkDevices: TnetworkDevice[]
  isGlobalLoading: boolean
  optionMenuActive: number
  connectedDevices: TconnectedDevice[]
  speedTests: TspeedTest[]
  auth: boolean
  setSpeedTests: (speedTests: []) => void
  setConnectedDevices: (connectedDevices: []) => void
  setOptionMenuActive: (option: number) => void
  setUserData: (userData: any) => void
  setNetworkData: (networkData: {}) => void
  setInternetScore: (internetScore: {}) => void
  setNetworkDevices: (networkDevices: []) => void
  setGlobalLoading: (isGlobalLoading: boolean) => void
}
const useUserData = (): TuseUserData => {
  const {
    globalState: {
      userData,
      internetScore,
      networkData,
      networkDevices,
      isGlobalLoading,
      optionMenuActive,
      connectedDevices,
      speedTests
    },
    setSpeedTests,
    setConnectedDevices,
    setOptionMenuActive,
    setUserData,
    setNetworkData,
    setInternetScore,
    setNetworkDevices,
    setGlobalLoading
  } = useContext(Context)
  return {
    auth: userData.auth !== undefined ? userData.auth : false,
    userData,
    internetScore,
    networkData,
    networkDevices,
    isGlobalLoading,
    optionMenuActive,
    connectedDevices,
    speedTests,
    setSpeedTests,
    setConnectedDevices,
    setOptionMenuActive,
    setUserData,
    setNetworkData,
    setInternetScore,
    setNetworkDevices,
    setGlobalLoading
  }
}

export default useUserData
