import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import LoadingBackdrop from 'components/shared/GlobalLoadingBackDrop'
import { ToastContainer } from 'react-toastify'
import Layout from 'components/Layout'

import ReactGA from 'react-ga4'
import './App.css'
import 'assets/sass/main.scss'
import 'react-toastify/dist/ReactToastify.css'
//import TagManager from 'react-gtm-module'

//Context

import { Provider } from 'context/Context'
import { PaymentProvider } from 'context/Payment/Context'

function App() {
  const TRACKING_ID: string | undefined = process.env.REACT_APP_GA_ID // OUR_TRACKING_ID
  if (TRACKING_ID !== undefined) ReactGA.initialize(TRACKING_ID)
  return (
    <BrowserRouter>
      <Provider>
        <PaymentProvider>
          <Layout>
            <LoadingBackdrop />
            <ToastContainer />
            <AppRoutes />
          </Layout>
        </PaymentProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App
