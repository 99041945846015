import { urls } from 'utils/urls'
import React from 'react'
import useUserData from './useUserData'
import { privateAxiosPaynet } from 'utils/axios'
import { notify } from 'utils/notify'
import usePaymentContext from './usePaymentContext'
const usePaymentMethod = () => {
  const { userData, setGlobalLoading } = useUserData()
  const { customer } = usePaymentContext()
  const updateDefaultPaymentMethod = async (
    stripeCustomerId: string,
    paymentMethod: string
  ) => {
    setGlobalLoading(true)
    try {
      const { data: response } = await privateAxiosPaynet.put(
        `customer/${stripeCustomerId}/payment-method/default`,
        {
          paymentMethod: paymentMethod,
          currentPaymentMethod:
            customer?.invoice_settings?.default_payment_method?.id
        }
      )
      notify('success', 'Método de pago actualizado correctamente.')
      return response
    } catch (error) {
      notify('error', 'Ocurrió un error al actualizar el método de pago.')
    } finally {
      setGlobalLoading(false)
    }
  }

  return {
    updateDefaultPaymentMethod
  }
}

export default usePaymentMethod
