const urls: { [key: string]: any } = {
  validateCustomerPasswordToken: 'getCustomerPasswordTokenValidation',
  updateCustomerPassword: 'putCustomerPassword',
  resetCustomerPassword: 'putCustomerSetPasswordToken',
  customerLogin: 'postCustomerLogin',
  customerLogout: 'postUserLogout',
  getCustomerServiceStatus: 'networkInfo',
  getCustomerInternetScore: 'score',
  getCustomerNetworkDevices: 'nodes',
  getSpeedTests: 'speedTest',
  getConnectedDevices: 'devices',
  newSpeedTest: 'speedTest',
  refreshToken: 'postCustomerRefreshToken',
  postAdministratorTokenValidation: 'postAdministratorTokenValidation',
  getCustomerCards: 'customer/cards',
  getCustomer: 'customer',
  getProducts: 'products',
  API_BILLING_getCfdiUses: 'catalogs/cfdi-uses',
  API_BILLING_getFiscalRegimes: 'catalogs/fiscal-regimes',
  API_BILLING_createCustomer: 'customer'
}

const urlBaseParams: { [key: string]: any } = {
  plume: {
    location_id: '',
    gateway_id: ''
  },
  eero: {},
  nokia: {}
}

type network = {
  type: string
  content: {} | any
}

const networkUrlBuilder = (network: network, urlName: string) => {
  let url: string = ''
  let params: object | any = {}
  switch (true) {
    case network.type.toUpperCase() === 'EERO':
      url =
        'network/' +
        network.type.toLowerCase() +
        '/' +
        network.content.id +
        '/' +
        urls[urlName]
      break

    case network.type.toUpperCase() === 'PLUME':
      url =
        'network/' +
        network.type.toLowerCase() +
        '/' +
        network.content.customer_id +
        '/' +
        urls[urlName]
      let baseParams = urlBaseParams[network.type.toLowerCase()]

      Object.entries(baseParams).forEach((entry) => {
        const [key] = entry
        params[key] = network.content[key]
      })
      break

    case network.type.toUpperCase() === 'NOKIA':
      url =
        'network/' +
        network.type.toLowerCase() +
        '/' +
        network.content.macAddress +
        '/' +
        urls[urlName]
      break

    default:
      break
  }
  return { url, params }
}

export { urls, networkUrlBuilder }
