import { urls } from 'utils/urls'
import React from 'react'
import useUserData from './useUserData'
import { privateAxiosPaynet } from 'utils/axios'
import { setSessionItem } from 'utils/session'

const useStripeData = () => {
  const { userData, setGlobalLoading } = useUserData()

  const createSetupIntent = async (stripeCustomerId: string) => {
    //setGlobalLoading(true)
    try {
      const { data: response } = await privateAxiosPaynet.post(`setup-intent`, {
        stripeCustomerId: stripeCustomerId
      })

      setSessionItem('stripeClientSecret', response?.clientSecret)
      return response
    } catch (error) {
    } finally {
      //setGlobalLoading(false)
    }
  }

  return {
    createSetupIntent
  }
}

export default useStripeData
