import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Card, CardContent } from '@mui/material'
import greenDot from 'assets/images/greenDot.png'
import redDot from 'assets/images/redDot.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import IconButton from '@mui/material/IconButton'
import useUserData from 'hooks/useUserData'
import _ from 'lodash'
import { TnetworkDevice } from 'types/types'

const ServiceStatus = () => {
  const { networkData, networkDevices } = useUserData()
  const navigate = useNavigate()

  const hasNetworkDevicesProblems = () => {
    return _.some(networkDevices, function (device: TnetworkDevice) {
      return device.signal <= 1 || device.connected === false
    })
  }

  let serviceStatusLed, serviceStatusText

  if (
    networkData.health.internet_status === 'connected' &&
    !hasNetworkDevicesProblems()
  ) {
    serviceStatusLed = greenDot
    serviceStatusText = 'Servicio activo'
  } else {
    serviceStatusLed = redDot
    serviceStatusText = 'Hay un problema'
  }

  return (
    <Card variant='outlined' className='homeContent-infoCards'>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div className='serviceStatus-led'>
              <img alt='' src={serviceStatusLed}></img>
            </div>
          </Grid>
          <Grid item xs={8}>
            <h4 className='serviceStatus-status axtel-text'>
              {serviceStatusText}
            </h4>
            <h5 className='serviceStatus-ago axtel-text'>hace 1 min</h5>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className='navigationButton axtel-icon-rounded'
              color='primary'
              aria-label='upload picture'
              component='label'
              onClick={() => navigate('/serviceStatus')}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ServiceStatus
