import React from 'react'
import { Grid, Button, Typography, Chip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export default function InvoiceModal({ invoice, isOpen, handleClose }: any) {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>
        <div>{`Factura del ${invoice.date}`}</div>
        <div>
          <Chip
            label={invoice?.paid ? 'pagada' : 'pendiente'}
            sx={{
              backgroundColor: invoice?.paid ? '#39C7A5' : '#EA4870',
              color: 'white'
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant='subtitle1'>Método de pago</Typography>
            <Typography variant='subtitle1'>
              {invoice.paymentMethodTypes.includes('card') &&
                'Tarjeta de crédito'}

              {invoice.paymentMethodTypes.includes('customer_balance') &&
                'Transferencia bancaria'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <TableContainer className='speedtests-tableContainer'>
              <Table
                className='serviceScore-speedtests-table'
                stickyHeader
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='subtitle1'>
                        <strong>Concepto</strong>
                      </Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      <Typography variant='subtitle1'>
                        <strong>Precio</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.lines.map((item: any, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0
                          }
                        }}
                      >
                        <TableCell>
                          <Typography variant='subtitle1'>
                            <strong>{item.name}</strong>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                          <Typography
                            variant='subtitle1'
                            style={
                              item.discount > 0
                                ? { textDecoration: 'line-through' }
                                : {}
                            }
                          >
                            $ {item.amount / 100} MXN
                          </Typography>
                          {item.discount > 0 && (
                            <Typography variant='subtitle1'>
                              $ {(item.amount - item.discount) / 100} MXN
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '1rem'
            }}
          >
            <Typography variant='subtitle1' style={{ fontStyle: 'italic' }}>
              {invoice.discountName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '1rem'
            }}
          >
            <Typography variant='subtitle1'>
              Total: $ {invoice.subtotal / 100} MXN
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleClose}>Cerrar</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
