import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { stripeErrors } from 'utils/constants'
import { notify } from 'utils/notify'
import BasicField from 'components/shared/BasicField'
import { Typography, Button, Grid } from '@mui/material'
function PaymentForm({ savePaymentmethod }: any) {
  const element_options: any = {
    fields: { billingDetails: { address: 'never' } },
    terms: { card: 'never' }
  }
  const stripe: any = useStripe()
  const elements: any = useElements()
  const [error, setError] = useState(null)
  const [cardHolderName, setCardHolderName] = useState<any>('')
  const [authorize, setAuthorize] = useState<any>(false)
  const [disableButton, setDisableButton] = useState<any>(false)

  const handleSubmit = async (event: any) => {
    setDisableButton(true)
    event.preventDefault()
    if (!stripe || !elements) {
      disableButton(false)
      return
    }
    const element = elements.getElement(PaymentElement)

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: '',
        payment_method_data: {
          billing_details: {
            name: cardHolderName,
            address: {
              country: 'MX',
              postal_code: '',
              state: '',
              city: '',
              line1: '',
              line2: ''
            }
          }
        }
      },
      redirect: 'if_required'
    })

    if (result.error) {
      notify(
        'error',
        (stripeErrors[result.error.code] || 'La tarjeta ha sido rechazada') +
          (stripeErrors[result.error.decline_code] || '')
      )
      // Handle error
    } else if (result.setupIntent.status === 'succeeded') {
      savePaymentmethod(result.setupIntent.payment_method)
      // SetupIntent confirmation succeeded
      console.log(result)
    }
    setDisableButton(false)
  }

  const isSubmitDisabled = !stripe || !elements || error

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BasicField
            label='Nombre del titular'
            name={'cardHolderName'}
            type='text'
            size={'small'}
            value={cardHolderName}
            onChange={(e: any) => {
              let value = e.target.value
              setCardHolderName(value)
            }}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <PaymentElement options={element_options} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' className='axtel-text'>
            Aplicaremos un cargo de $1 peso para validar tu tarjeta, el cual se
            reembolsa de manera automática
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            //variant='contained'
            className='axtel-button-full'
            type='submit'
            disabled={disableButton}
            fullWidth
          >
            Guardar método de pago
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default PaymentForm
