import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { on } from 'events'

export default function BasicSelect({
  value,
  options,
  onChange,
  name,
  label,
  disabled = false
}: {
  value: string
  options: any[]
  onChange: any
  name: string
  label: string
  disabled?: boolean
}) {
  const excludedFields = ['fiscalRegime']
  return (
    <>
      <FormControl sx={{ width: '100%' }} size='medium'>
        <InputLabel id='demo-select-small-label'>{label}</InputLabel>
        <Select
          labelId='demo-select-small-label'
          id='demo-select-small'
          value={value}
          label={label}
          onChange={onChange}
          name={name}
          disabled={disabled}
        >
          {options.map((option: any, index: number) => (
            <MenuItem
              key={index}
              value={excludedFields.includes(name) ? option.value : option.name}
            >
              {option.value} - {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
