export const reducer = (globalState: any, action: any): any => {
  switch (action.type) {
    case 'SET_CUSTOMER':
      return {
        ...globalState,
        customer: action.payload
      }
    case 'SET_INVOICES':
      return {
        ...globalState,
        invoices: action.payload
      }

    case 'SET_SUBSCRIPTION':
      return {
        ...globalState,
        subscription: action.payload
      }

    case 'SET_CHARGES':
      return {
        ...globalState,
        charges: action.payload
      }

    default:
      return globalState
  }
}
