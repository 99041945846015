import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, IconButton, Card, CardContent } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import InternetScoreRating from 'components/shared/InternetScoreRating'

const ServiceScore = () => {
  const navigate = useNavigate()

  return (
    <Card variant='outlined' className='homeContent-infoCards'>
      <CardContent>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={10}>
            <h4 className='serviceScore-title axtel-text'>
              Calificación de velocidad
            </h4>
            <Box
              sx={{
                '& > legend': { mt: 2 }
              }}
            >
              <InternetScoreRating />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className='navigationButton axtel-icon-rounded'
              //color='primary'
              aria-label='upload picture'
              component='label'
              onClick={() => navigate('/internetScore')}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ServiceScore
