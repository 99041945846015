import React from 'react'
import { Grid, Card, CardContent, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
export default function BillingData({ data }: any) {
  const navigate = useNavigate()

  return (
    <div>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} lg={6}>
          <Card style={{ margin: 5 }} variant='outlined'>
            {isEmpty(data) ? (
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='caption' className='axtel-text'>
                      Información para facturar
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className='axtel-button-outlined'
                      style={{ width: '100%' }}
                      onClick={() => {
                        navigate('/billing')
                      }}
                    >
                      <Typography variant='caption'>
                        Registrar información fiscal
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            ) : (
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='caption' className='axtel-text'>
                      Información para facturar
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Typography variant='subtitle1' className='axtel-text'>
                      {data?.Name}
                    </Typography>
                    <Typography variant='subtitle2' className='axtel-text'>
                      {data?.Rfc}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Button
                      onClick={() => {
                        navigate('/billing')
                      }}
                      variant='outlined'
                      style={{ width: '100%' }}
                    >
                      <Typography variant='caption'>Editar</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
