import { privateAxios } from '../utils/axios'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useUserData from './useUserData'

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { auth } = useUserData()

  useEffect(() => {
    const responseIntercept = privateAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true
          await refresh()
          return privateAxios(prevRequest)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      privateAxios.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh])

  return privateAxios
}

export default useAxiosPrivate
