import React, { useState } from 'react'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Stack
} from '@mui/material'
import useUserData from 'hooks/useUserData'
import usePaymentData from 'hooks/usePaymentData'
import ErrorIcon from '@mui/icons-material/Error'
import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import { notify } from 'utils/notify'
import { formatUnixTimestampString } from 'utils/time'

dayjs.locale(es)
export default function Subscription({ data, logs }: any) {
  const [isDisabledRetryPaymentButton, setIsDisabledRetryPaymentButton] =
    useState(false)
  const { userData, setGlobalLoading, setUserData } = useUserData()
  const {
    retryPayment,
    invoices,
    getSubscription,
    getInvoices,
    getCustomer,
    getCharges,
    charges
  } = usePaymentData()

  const handleOpenBankInformation = () => {
    window.open(data?.latestInvoice?.hosted_invoice_url, '_blank')
  }

  const handleRetryPayment = async () => {
    try {
      setIsDisabledRetryPaymentButton(true)
      //setGlobalLoading(true)

      let result = await retryPayment()
      if (result['success'] === true) {
        const promises: Promise<void>[] = []
        promises.push(getSubscription())
        promises.push(getCustomer())
        promises.push(getInvoices())
        Promise.allSettled(promises)
          .then(async (results) => {
            setUserData({
              ...userData,
              status: 'ACTIVE',
              statusDebt: 'NO_DEBT'
            })
            if (results[1].status === 'fulfilled') {
              let resultGetCustomer = results[1].value as any
              await getCharges({ stripeCustomerId: resultGetCustomer['id'] })
            }
          })
          .finally(() => {
            setIsDisabledRetryPaymentButton(false)
          })
        notify('success', 'Pago recibido')
      } else {
        throw Error('Sucedió un erro al momento de realizar el pago')
      }
    } catch (error) {
      const promises: Promise<void>[] = []
      promises.push(getInvoices())
      if (error.message === 'declined') {
        notify('error', 'El pago ha sido rechazado')
      } else {
        notify('error', 'Sucedió un error al momento de realizar el pago')
      }
    } finally {
      setGlobalLoading(false)
      setIsDisabledRetryPaymentButton(false)
    }
    /*     const setupIntent = await createSetupIntent(customer?.id)
      setOpenDialog(true) */
  }
  const enableRetryPayment =
    (userData.statusDebt === 'SUSPENDED' &&
      data.collectionMethod !== 'send_invoice') ||
    (userData.statusDebt === 'LAST_PAYMENT_FAIL' &&
      data.collectionMethod !== 'send_invoice')

  let lastInvoice = invoices?.[0]
  return (
    <div>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} lg={6}>
          <Card style={{ margin: 5 }} variant='outlined'>
            <CardContent>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={12}>
                  <Typography variant='caption' className='axtel-text'>
                    Plan contratado
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h6' className='axtel-text'>
                    {userData?.currentOffer?.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h4' color='primary'>
                    $
                    {(
                      data?.upcomingInvoice?.amount_due / 100
                    )?.toLocaleString()}{' '}
                    MXN
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='subtitle2' className='axtel-text'>
                    Próximo pago: {data.nextPaymentDate}
                  </Typography>
                </Grid>

                {data.collectionMethod === 'send_invoice' &&
                  data.latestInvoice?.status === 'open' && (
                    <>
                      <Grid item xs={9} container justifyContent={'center'}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <Typography
                            sx={{
                              textAlign: 'center',
                              color: '#eca317',
                              fontWeight: 'bold'
                            }}
                          >
                            {`Pago pendiente ${dayjs
                              .unix(data.latestInvoice?.created)
                              .locale(es)
                              .format('DD MMMM YYYY')} `}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          fullWidth
                          //variant='contained'
                          className='axtel-button-full'
                          onClick={handleOpenBankInformation}
                        >
                          Realizar pago ahora
                        </Button>
                      </Grid>
                    </>
                  )}
                <br />
                <br />
                <br />
                {enableRetryPayment && charges?.length > 0 && (
                  <>
                    <Grid item xs={9} container justifyContent={'center'}>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                      >
                        <ErrorIcon sx={{ color: '#EC3117' }} />
                        <Typography
                          variant='subtitle2'
                          sx={{
                            textAlign: 'center',
                            color: '#EC3117',
                            fontWeight: 'bold'
                          }}
                        >
                          {`Pago rechazado ${dayjs
                            .unix(charges[0]?.created)
                            .locale(es)
                            .format('DD MMMM YYYY')} `}
                        </Typography>
                      </Stack>
                    </Grid>
                    {logs.length > 0 && (
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='subtitle2' className='axtel-text'>
                          Próximo intento:{' '}
                          {logs[0]?.content?.nextAttempt
                            ? formatUnixTimestampString(
                                logs[0]?.content?.nextAttempt
                              )
                            : '-'}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={9}>
                      <Button
                        fullWidth
                        //variant='contained'
                        className='axtel-button-full'
                        onClick={handleRetryPayment}
                        disabled={isDisabledRetryPaymentButton}
                      >
                        Reintentar pago ahora
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
