import { privateAxiosPaynet } from 'utils/axios'

export const getCustomerCharges = async ({
  stripeCustomerId,
  limit = 5
}: {
  stripeCustomerId: string
  limit: number
}) => {
  try {
    const { data: response } = await privateAxiosPaynet.get(
      `customer/${stripeCustomerId}/charges`,
      { params: { limit } }
    )
    return response
  } catch (error) {
    console.log(error)
  }
}
