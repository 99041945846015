/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshToken from 'hooks/useRefreshToken'
import useUserData from 'hooks/useUserData'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const refreshToken = useRefreshToken()
  const { auth } = useUserData()

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)

    const verifyRefreshToken = async () => {
      try {
        await refreshToken()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }
    !auth ? verifyRefreshToken() : setIsLoading(false)
    return () => {
      isMounted = false
    }
  }, [])

  return <>{isLoading ? <></> : <Outlet />}</>
}

export default PersistLogin
