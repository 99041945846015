import useUserData from './useUserData'
import usePrivateAxiosNetwork from './usePrivateAxiosNetwork'
import { networkUrlBuilder } from 'utils/urls'

type serviceConstructorType = {
  url: string
  params: object
}

const useRefreshNetworkData = () => {
  const { userData, setNetworkData, setInternetScore, setNetworkDevices } =
    useUserData()

  const privateAxiosNetwork = usePrivateAxiosNetwork()
  const refreshNetworkData = async () => {
    const simetricBandwidth: string =
      userData.currentOffer?.products[0]?.metadata?.bandwidth ||
      userData.offer?.upload

    try {
      if (
        userData?.network?.type === '' ||
        userData?.network?.type === undefined
      ) {
        setNetworkData({})
        setInternetScore({})
        setNetworkDevices([])
        return
      } else {
        let getCustomerServiceStatus: serviceConstructorType = {
          url: '',
          params: {}
        }
        let getCustomerInternetScore: serviceConstructorType = {
          url: '',
          params: {}
        }
        let getCustomerNetworkDevices: serviceConstructorType = {
          url: '',
          params: {}
        }

        getCustomerServiceStatus = networkUrlBuilder(
          userData.network,
          'getCustomerServiceStatus'
        )
        getCustomerInternetScore = networkUrlBuilder(
          userData.network,
          'getCustomerInternetScore'
        )
        getCustomerNetworkDevices = networkUrlBuilder(
          userData.network,
          'getCustomerNetworkDevices'
        )
        const serviceStatusRequest = await privateAxiosNetwork.get(
          getCustomerServiceStatus.url,
          {
            params: getCustomerServiceStatus.params
          }
        )
        const internetScoreRequest = await privateAxiosNetwork.get(
          getCustomerInternetScore.url,
          {
            params: {
              upload_base: simetricBandwidth,
              download_base: simetricBandwidth,
              ...getCustomerInternetScore.params
            }
          }
        )
        const networkDevicesRequest = await privateAxiosNetwork.get(
          getCustomerNetworkDevices.url,
          {
            params: getCustomerNetworkDevices.params
          }
        )

        let [serviceStatusResult, internetScoreResult, networkDevicesResult] =
          await Promise.all([
            serviceStatusRequest,
            internetScoreRequest,
            networkDevicesRequest
          ])

        setNetworkData(serviceStatusResult.data)
        setInternetScore(internetScoreResult.data)
        setNetworkDevices(networkDevicesResult.data)
      }
    } catch (error) {
      setNetworkData({})
      setInternetScore({})
      setNetworkDevices([])
      throw new Error('Error al intentar recuperar la información del cliente')
    }

    //return response.data.accessToken
  }
  return refreshNetworkData
}

export default useRefreshNetworkData
