import ReactGA from 'react-ga4'
import useUserData from './useUserData'

const useGoogleAnalytics = () => {
  const { userData, auth } = useUserData()
  const newEvent = async (category: string, action: string) => {
    ReactGA.event({
      category,
      action,
      ...(auth &&
        userData.customerId !== undefined && { userId: userData.customerId })
    })
  }

  const newPageView = async (pathname: string) => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      ...(auth &&
        userData.customerId !== undefined && { userId: userData.customerId })
    })
  }
  return { newPageView, newEvent }
}

export default useGoogleAnalytics
