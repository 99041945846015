import React, { ReactElement, createContext, useReducer } from 'react'
import { reducer } from './Reducer'

export const initialState: any = {
  customer: {},
  invoices: [],
  subscription: {},
  lastCharges: {}
}

export const usePaymentContext = (initialState: any) => {
  const [globalState, dispatch] = useReducer(reducer, initialState)
  const setCustomerData = (customer: any) => {
    try {
      dispatch({
        type: 'SET_CUSTOMER',
        payload: customer
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setInvoices = (invoices: []) => {
    try {
      dispatch({
        type: 'SET_INVOICES',
        payload: invoices
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setSubscription = (subscription: any) => {
    try {
      dispatch({
        type: 'SET_SUBSCRIPTION',
        payload: subscription
      })
    } catch (error) {
      console.log(error)
    }
  }
  const setCharges = (charges: any) => {
    try {
      dispatch({
        type: 'SET_CHARGES',
        payload: charges
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    globalState,
    setCustomerData,
    setSubscription,
    setInvoices,
    setCharges
  }
}

export const initContextState: ReturnType<typeof usePaymentContext> = {
  globalState: initialState,
  setCustomerData: (customer: any) => {},
  setSubscription: (subscription: any) => {},
  setInvoices: (invoices: any) => {},
  setCharges: (charges: any) => {}
}

export const PaymentContext =
  createContext<ReturnType<typeof usePaymentContext>>(initContextState)

export const PaymentProvider = ({
  children
}: {
  children: ReactElement | ReactElement[] | undefined
}): ReactElement => {
  return (
    <PaymentContext.Provider value={usePaymentContext(initialState)}>
      {children}
    </PaymentContext.Provider>
  )
}
