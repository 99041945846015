import React, { useState } from 'react'
import { privateAxiosBilling } from 'utils/axios'
import useUserData from './useUserData'
import { urls } from 'utils/urls'
const useBillingCatalog = () => {
  const { userData } = useUserData()
  const [municipalities, setMunicipalities] = useState([])
  const [localities, setLocalities] = useState([])
  const [fiscalRegimes, setFiscalRegimes] = useState([])
  const [states, setStates] = useState<any>([])
  const [countries, setCountries] = useState([])

  React.useEffect(() => {
    const getData = async () => {
      await getFiscalRegimes(userData?.customerType)
      await getStates()
    }
    getData()
  }, [])

  const getCfdiUses = async (customerType: string) => {
    try {
      const { data: response } = await privateAxiosBilling.get(
        urls.API_BILLING_getCfdiUses,
        {
          params: {
            personType: customerType === 'business' ? 'Moral' : 'Natural'
          }
        }
      )
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getFiscalRegimes = async (customerType: string) => {
    try {
      const { data: response } = await privateAxiosBilling.get(
        urls.API_BILLING_getFiscalRegimes
      )
      setFiscalRegimes(response?.data)
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getLocalities = async (state: string) => {
    let stateCode = states.find((item: any) => item.name === state)?.value
    try {
      const { data: response } = await privateAxiosBilling.get(
        `/catalogs/states/${stateCode}/localities`
      )

      setLocalities(response?.data)
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getMunicipalities = async (state: string) => {
    let stateCode = states.find((item: any) => item.name === state)?.value
    try {
      const { data: response } = await privateAxiosBilling.get(
        `/catalogs/states/${stateCode}/municipalities`
      )
      setMunicipalities(response?.data)
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getCountries = async () => {
    try {
      const { data: response } = await privateAxiosBilling.get(
        'catalogs/countries'
      )
      setCountries(response?.data)
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getStates = async () => {
    try {
      const { data: response } = await privateAxiosBilling.get(
        'catalogs/countries/MEX/states'
      )

      setStates(response?.data)
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const validateFiscalData = async (payload: any) => {
    try {
      const { data: response } = await privateAxiosBilling.post(
        'customer/validate-profile',
        payload
      )
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  return {
    getCfdiUses,
    getFiscalRegimes,
    getStates,
    getCountries,
    validateFiscalData,
    getMunicipalities,
    getLocalities,
    municipalities,
    localities,
    fiscalRegimes,
    states,
    countries
  }
}

export default useBillingCatalog
