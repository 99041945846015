import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

export default function Suspended() {
  return (
    <div>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} lg={6}>
          <Card
            style={{ margin: 5 }}
            variant='outlined'
            sx={{
              borderColor: 'white',
              borderRadius: '10px',
              backgroundColor: '#EC3117'
            }}
          >
            <CardContent sx={{ padding: '5px !important' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      textAlign: 'left',
                      color: 'white',
                      fontWeight: 'bold'
                      //paddingTop: '10px'
                    }}
                  >
                    Servicio suspendido
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
