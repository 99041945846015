/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Rating } from '@mui/material'
import useUserData from 'hooks/useUserData'
//import useGoogleTagManager from 'hooks/useGoogleTagManager'

const InternetScoreRating = () => {
  const { internetScore } = useUserData()
  const [value, setValue] = React.useState<number>(0)
  useEffect(() => {
    if (internetScore.score !== null) {
      switch (true) {
        case internetScore?.score < 59:
          setValue(1)
          break
        case internetScore?.score >= 60 && internetScore?.score < 70:
          setValue(2)
          break
        case internetScore?.score >= 70 && internetScore?.score < 80:
          setValue(3)
          break
        case internetScore?.score >= 80 && internetScore?.score < 90:
          setValue(4)
          break
        case internetScore?.score >= 90:
          setValue(5)
          break
        default:
          setValue(0)
          break
      }
    }
  }, [internetScore])

  return (
    <div className='internetScore'>
      {internetScore?.score ? (
        <Rating size='large' name='read-only' value={value} readOnly />
      ) : (
        <h4 className='serviceScore-error'>
          De momento no podemos calcular la calificación de tu servicio.
        </h4>
      )}
    </div>
  )
}

export default InternetScoreRating
