export const validateBillingProfile = (payload: any) => {
  const obj = payload

  if (
    !Boolean(obj?.ExistRfc) ||
    !Boolean(obj?.MatchFiscalRegime) ||
    !Boolean(obj?.MatchName) ||
    !Boolean(obj?.MatchZipCode)
  ) {
    return false
  } else {
    return true
  }
}

export const validateCustomerForm = (customer: any) => {
  const fieldsNotIncluded = ['emailOp1', 'emailOp2']
  for (const key in customer) {
    if (customer[key] === '' && !fieldsNotIncluded.includes(key)) {
      return false
    }
  }
  return true
}

export const validateAddressForm = (address: any) => {
  const fieldsNotIncluded = ['interiorNumber', 'zipCode']
  for (const key in address) {
    if (address[key] === '' && !fieldsNotIncluded.includes(key)) {
      return false
    }
  }
  return true
}
