/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useLogout from './hooks/useLogout'

const Logout = () => {
  useLogout()
  return <div className='LogoutContent'>Logout</div>
}

export default Logout
