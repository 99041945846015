import React from 'react'
import { Grid, Card, CardContent, Typography, Button } from '@mui/material'
import BasicField from 'components/shared/BasicField'
import BasicSelect from 'components/shared/BasicSelect'
import useBillingCatalog from 'hooks/useBillingCatalog'
import { isEmpty } from 'lodash'

//hooks
import useUserData from 'hooks/useUserData'
// utils
import { excludedAcceptedRegimes } from 'utils/constants'
import {
  validateCustomerForm,
  validateAddressForm
} from 'utils/validations/billing'
export default function BillingDataForm({ billingCustomer, submitForm }: any) {
  const { userData } = useUserData()
  const {
    fiscalRegimes,
    states,
    localities,
    municipalities,
    getLocalities,
    getMunicipalities
  } = useBillingCatalog()
  const [customer, setCustomer] = React.useState<any>({
    rfc: '',
    name: '',
    email: userData?.email,
    emailOp1: '',
    emailOp2: '',
    fiscalRegime: '',
    cfdiUse: 'CP01'
  })
  const [address, setAddress] = React.useState<any>({
    street: '',
    exteriorNumber: '',
    interiorNumber: '',
    neighborhood: '',
    zipCode: '',
    locality: '',
    municipality: '',
    state: '',
    country: 'MEX'
  })

  React.useEffect(() => {
    if (!isEmpty(billingCustomer)) {
      setCustomer({
        name: billingCustomer?.Name,
        rfc: billingCustomer?.Rfc,
        email: billingCustomer?.Email,
        fiscalRegime: billingCustomer?.FiscalRegime,
        cfdiUse: billingCustomer?.CfdiUse
      })
      setAddress({
        ...address,

        street: billingCustomer?.Address?.Street,
        exteriorNumber: billingCustomer?.Address?.ExteriorNumber,
        interiorNumber: billingCustomer?.Address?.InteriorNumber,
        neighborhood: billingCustomer?.Address?.Neighborhood,
        locality: billingCustomer?.Address?.Locality,
        municipality: billingCustomer?.Address?.Municipality,
        state: billingCustomer?.Address?.State,
        zipCode: billingCustomer?.Address?.ZipCode
      })
    }
  }, [billingCustomer])

  React.useEffect(() => {
    if (Boolean(address?.state) && !isEmpty(states)) {
      getLocalities(address?.state)
      getMunicipalities(address?.state)
    }
  }, [address, states])

  React.useEffect(() => {
    if (excludedAcceptedRegimes.includes(customer.fiscalRegime)) {
      setCustomer({ ...customer, cfdiUse: 'G03' })
    } else {
      setCustomer({ ...customer, cfdiUse: 'CP01' })
    }
  }, [customer.fiscalRegime])

  const customerHandleChange = (e: any) => {
    let value = ''
    if (e.target.name === 'name') {
      value = e.target.value.toUpperCase()
    } else {
      value = e.target.value
    }
    setCustomer({
      ...customer,
      [e.target.name]: value
    })
  }

  const addressHandleChange = (e: any) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    const payload = customer
    payload.customerId = userData?.customerId
    payload.address = address

    submitForm(payload)
  }

  return (
    <div>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} lg={6}>
          <Card style={{ margin: 5, marginBottom: '40%' }} variant='outlined'>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h6' className='axtel-text'>
                    Información de facturación
                  </Typography>
                  <Typography variant='body2' className='axtel-text'>
                    Si deseas generar una factura o próximas facturas de tu
                    Servicio de Internet, agradeceremos nos proporciones la
                    siguiente información
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <BasicField
                    label='Nombre / Razón Social'
                    value={customer.name}
                    required={true}
                    type='text'
                    name='name'
                    onChange={customerHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    required={true}
                    label='RFC'
                    value={customer.rfc}
                    type='text'
                    name='rfc'
                    onChange={customerHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='Email'
                    value={customer.email}
                    type='email'
                    name='email'
                    disabled={true}
                    onChange={customerHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='Uso de la factura'
                    value={customer.cfdiUse}
                    type='text'
                    disabled={true}
                    name='cfdiUse'
                    onChange={customerHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicSelect
                    label='Régimen fiscal'
                    value={customer?.fiscalRegime}
                    options={fiscalRegimes}
                    onChange={customerHandleChange}
                    name='fiscalRegime'
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='C.P.'
                    required={true}
                    value={address.zipCode}
                    type='text'
                    name='zipCode'
                    onChange={addressHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <BasicField
                    label='Calle'
                    value={address.street}
                    type='text'
                    name='street'
                    onChange={addressHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='Numero exterior'
                    value={address.exteriorNumber}
                    type='text'
                    name='exteriorNumber'
                    onChange={addressHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='Numero interior'
                    value={address.interiorNumber}
                    type='text'
                    name='interiorNumber'
                    onChange={addressHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='Colonia'
                    value={address.neighborhood}
                    type='text'
                    name='neighborhood'
                    onChange={addressHandleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicSelect
                    label='Estado'
                    value={address?.state}
                    options={states}
                    onChange={addressHandleChange}
                    name='state'
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicSelect
                    label='Localidad'
                    value={address?.locality}
                    options={localities}
                    onChange={addressHandleChange}
                    name='locality'
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicSelect
                    label='Municipio'
                    value={address?.municipality}
                    options={municipalities}
                    onChange={addressHandleChange}
                    name='municipality'
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BasicField
                    label='País'
                    value={address.country + ' - México'}
                    type='text'
                    disabled={true}
                    name='country'
                    onChange={addressHandleChange}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    onClick={handleSubmit}
                    disabled={
                      !validateCustomerForm(customer) ||
                      !validateAddressForm(address)
                    }
                    style={{ width: '100%' }}
                    className={
                      !validateCustomerForm(customer) ||
                      !validateAddressForm(address)
                        ? ''
                        : 'axtel-button-outlined'
                    }
                  >
                    Guardar Información
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
