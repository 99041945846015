import React, { ReactElement, createContext, useReducer } from 'react'
import { reducer } from './Reducer'
import { TinitialState } from './types'

export const initialState: TinitialState = {
  userData: {},
  internetScore: {},
  networkData: {},
  networkDevices: [],
  isGlobalLoading: false,
  optionMenuActive: 0,
  connectedDevices: [],
  speedTests: [],
  auth: false
}

export const useUserContext = (initialState: TinitialState) => {
  const [globalState, dispatch] = useReducer(reducer, initialState)
  const setSpeedTests = (speedTests: []) => {
    try {
      dispatch({
        type: 'SET_SPEEDTESTS',
        payload: speedTests
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setConnectedDevices = (connectedDevices: []) => {
    try {
      dispatch({
        type: 'SET_CONNECTEDDEVICES',
        payload: connectedDevices
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setOptionMenuActive = (option: number) => {
    try {
      dispatch({
        type: 'SET_OPTIONMENUACTIVE',
        payload: option
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setUserData = (userData: {}) => {
    try {
      dispatch({
        type: 'SET_USERDATA',
        payload: userData
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setNetworkData = (networkData: {}) => {
    try {
      dispatch({
        type: 'SET_NETWORKDATA',
        payload: networkData
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setInternetScore = (internetScore: {}) => {
    try {
      dispatch({
        type: 'SET_INTERNETSCORE',
        payload: internetScore
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setNetworkDevices = (networkDevices: []) => {
    try {
      dispatch({
        type: 'SET_NETWORKDEVICES',
        payload: networkDevices
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setGlobalLoading = (isGlobalLoading: boolean) => {
    dispatch({
      type: 'SET_LOADING',
      payload: isGlobalLoading
    })
  }

  return {
    globalState,
    setSpeedTests,
    setConnectedDevices,
    setOptionMenuActive,
    setUserData,
    setNetworkData,
    setInternetScore,
    setNetworkDevices,
    setGlobalLoading
  }
}

export const initContextState: ReturnType<typeof useUserContext> = {
  globalState: initialState,
  setSpeedTests: (speedTests: []) => {},
  setConnectedDevices: (connectedDevices: []) => {},
  setOptionMenuActive: (option: number) => {},
  setUserData: (userData: {}) => {},
  setNetworkData: (networkData: {}) => {},
  setInternetScore: (internetScore: {}) => {},
  setNetworkDevices: (networkDevices: []) => {},
  setGlobalLoading: (isGlobalLoading: boolean) => {}
}

export const Context =
  createContext<ReturnType<typeof useUserContext>>(initContextState)

export const Provider = ({
  children
}: {
  children: ReactElement | ReactElement[] | undefined
}): ReactElement => {
  return (
    <Context.Provider value={useUserContext(initialState)}>
      {children}
    </Context.Provider>
  )
}
