import { Button, Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import redDot from 'assets/images/redDot.png'

export default function Suspended() {
  const navigate = useNavigate()
  return (
    <Card variant='outlined' className='homeContent-suspended'>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <div className='serviceStatus-led'>
              <img alt='' src={redDot}></img>
            </div>
          </Grid>
          <Grid item xs={10}>
            <h4 className='serviceStatus-suspended axtel-text'>
              Servicio suspendido
            </h4>
            <h6 className='serviceStatus-due axtel-text'>
              Hubo un problema al recibir tu último pago
            </h6>
            <Button
              className='axtel-button-full'
              onClick={() => navigate('/payments')}
            >
              Actualiza tu método de pago
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
