import React from 'react'
import { Grid } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import Stack from '@mui/material/Stack'
import useUserData from 'hooks/useUserData'

const MyService = () => {
  const { userData } = useUserData()
  return (
    <div className='homeContent-myService'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className='myService-title axtel-text'>Mi servicio</h4>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <h5 className='myService-bundle axtel-text'>
              {userData?.currentOffer?.name || userData.offer?.name}
            </h5>
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            <Grid item xs={5}>
              <div className='myService-speed'>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  <p className='axtel-text'>
                    {userData.currentOffer?.products[0]?.metadata?.bandwidth ||
                      userData.offer?.download}
                  </p>
                  <DownloadIcon className='axtel-icon' />
                </Stack>
                <p className='axtel-text'>Descarga</p>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className='myService-speed'>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  <p className='axtel-text'>
                    {userData.currentOffer?.products[0]?.metadata?.bandwidth ||
                      userData.offer?.upload}
                  </p>
                  <UploadIcon className='axtel-icon' />
                </Stack>
                <p className='axtel-text'>Subida</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default MyService
