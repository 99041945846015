import { privateAxios } from 'utils/axios'
import { urls } from 'utils/urls'
export const getCustomerService = async ({
  query,
  fields
}: {
  query: string
  fields: string
}) => {
  try {
    const { data: response } = await privateAxios.get(urls.getCustomer, {
      params: { query, fields }
    })
    return response
  } catch (error) {
    console.log(error)
  }
}
