/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshNetworkData from '../../hooks/useRefreshNetworkData'
import useUserData from '../../hooks/useUserData'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

const PersistNetworkData = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const refreshNetworkData = useRefreshNetworkData()
  const { auth, internetScore, networkData, networkDevices, setGlobalLoading } =
    useUserData()
  useEffect(() => {
    setGlobalLoading(true)
    let isMounted = true
    const verifyRefreshNetworkData = async () => {
      try {
        await refreshNetworkData()
      } catch (err) {
        console.error(err)
        navigate('/error')
      } finally {
        isMounted && setIsLoading(false)
      }
    }
    if (
      (_.isEmpty(internetScore) ||
        _.isEmpty(networkData) ||
        _.isEmpty(networkDevices)) &&
      auth
    ) {
      verifyRefreshNetworkData()
    } else {
      setIsLoading(false)
    }

    setGlobalLoading(false)

    return () => {
      isMounted = false
    }
  }, [auth])

  return <>{isLoading ? <> </> : <Outlet />}</>
}

export default PersistNetworkData
