/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  Button,
  Radio,
  Paper,
  Box,
  Rating
} from '@mui/material'
import dayjs from 'dayjs'

import xtremoLogo from 'assets/images/xtremo-color.png'

import { notify } from 'utils/notify'

import { useLocation } from 'react-router-dom'
import useFieldServices from 'hooks/useFieldServices'
import { toLocalTZ } from 'utils/time'

const OrderInstallationSurvey = () => {
  const { order, orderRating, getOrder, updateOrderRating } = useFieldServices()
  const [validated, setValidated] = useState(false)

  const [isOrderLoading, setOrderLoading] = useState(true)
  const [isSurveyLoading, setSurveyLoading] = useState(false)
  const [finishedSurvey, setFinishedSurvey] = useState(false)

  const [rating, setRating] = useState<{
    rating: number | null
    comment: string
  }>({
    rating: null,
    comment: ''
  })

  let search = useLocation().search
  let orderId = new URLSearchParams(search).get('id')

  let ratings = [
    { value: 5, label: '5-Muy satisfecho' },
    { value: 4, label: '4-Satisfecho' },
    { value: 3, label: '3-Regular' },
    { value: 2, label: '2-Poco satisfecho' },
    { value: 1, label: '1-Insatisfecho' }
  ]

  useEffect(() => {
    if (orderId) {
      setOrderLoading(true)
      getOrder(orderId)
    }
  }, [orderId])

  useEffect(() => {
    if (order?.success) {
      setValidated(true)
      setOrderLoading(false)
      if (order.order.orderRating?.rating) {
        setFinishedSurvey(true)
      }
    } else if (order?.success === false) {
      setOrderLoading(false)
      notify('error', 'Error al obtener la encuesta')
    }
  }, [order])

  useEffect(() => {
    if (orderRating?.success) {
      setSurveyLoading(false)
      setFinishedSurvey(true)
    } else if (orderRating?.success === false) {
      setSurveyLoading(false)
      notify('error', 'Error al enviar la encuesta')
    }
  }, [orderRating])

  const isDisabled = () => {
    let availability = rating.rating

    return !availability || isSurveyLoading
  }

  const handleSubmit = () => {
    setSurveyLoading(true)

    updateOrderRating(order.order.orderId, rating.rating ?? 1, rating.comment)
  }

  const handleChangeRating = (newRating: number | null) => {
    setRating((prevState: any) => ({
      ...prevState,
      rating: newRating
    }))
  }

  const handleChangeComment = (newComment: string) => {
    setRating((prevState: any) => ({
      ...prevState,
      comment: newComment
    }))
  }

  return (
    <div style={{ paddingTop: '4rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img alt='' src={xtremoLogo} style={{ width: '200px' }} />
        </Box>

        {validated ? (
          finishedSurvey ? (
            <Box
              sx={{ paddingTop: 8, display: 'flex', justifyContent: 'center' }}
            >
              <Box sx={{ width: '70%' }}>
                <Box
                  sx={{
                    textAlign: 'center',
                    color: '#334155',
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                >
                  ¡Gracias!
                </Box>
                <Box sx={{ marginTop: 2, width: '100%' }}>
                  <br />
                  <Box
                    sx={{ color: '#64748B', fontSize: 16, textWrap: 'wrap' }}
                  >
                    Te agradecemos por contestar esta encuesta, tus respuestas
                    nos ayudan a seguir mejorando nuestro servicio
                  </Box>
                  <br />
                  <Box
                    sx={{ color: '#64748B', fontSize: 12, textAlign: 'center' }}
                  >
                    {order?.order?.orderRating?.dateRated ? (
                      <>
                        Respondida el
                        <Box sx={{ fontWeight: 'bold' }}>
                          {dayjs(
                            toLocalTZ(order?.order?.orderRating?.dateRated)
                          ).format('DD-MM-YYYY')}
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  paddingTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    color: '#334155',
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                >
                  Encuesta de satisfacción
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{ width: '70%', marginLeft: '15%' }}>
                  <Box sx={{ paddingTop: 4 }}>
                    <Box
                      sx={{ color: '#64748B', fontSize: 16, textWrap: 'wrap' }}
                    >
                      Por favor, indícanos tu nivel de satisfacción con el
                      servicio de instalación.
                    </Box>
                    <Box
                      sx={{
                        paddingTop: 4,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Rating
                        size='large'
                        value={rating.rating}
                        onChange={(event, newValue) => {
                          handleChangeRating(newValue)
                        }}
                      />
                    </Box>

                    {/*ratings.map((rt) => (
                    <div
                      key={rt.value}
                      onClick={() => handleChangeRating(rt.value)}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Radio
                        checked={rating.rating == rt.value}
                        onChange={() => handleChangeRating(rt.value)}
                        value={rt.value}
                        name='ratingOption'
                      />
                      <Typography variant='subtitle1'>{rt.label}</Typography>
                    </div>
                  ))*/}
                    <Box sx={{ paddingTop: 4, color: '#64748B', fontSize: 16 }}>
                      Comentarios
                    </Box>
                    <TextField
                      label=''
                      fullWidth
                      multiline
                      rows={2}
                      value={rating.comment}
                      onChange={(e) => handleChangeComment(e.target.value)}
                    />
                    <Box
                      sx={{
                        paddingTop: 4,
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        disabled={isDisabled()}
                        variant='contained'
                        onClick={handleSubmit}
                        style={{
                          width: '100%',
                          backgroundColor: isDisabled()
                            ? '##64748B'
                            : '#EC3117',
                          color: 'white'
                        }}
                      >
                        ENVIAR ENCUESTA
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )
        ) : isOrderLoading ? (
          <Box
            sx={{ paddingTop: 8, display: 'flex', justifyContent: 'center' }}
          >
            <Box
              sx={{
                width: '70%',
                color: '#64748B',
                fontSize: 16,
                textAlign: 'center'
              }}
            >
              cargando encuesta...
            </Box>
          </Box>
        ) : (
          <Box
            sx={{ paddingTop: 8, display: 'flex', justifyContent: 'center' }}
          >
            <Box
              sx={{
                width: '70%',
                color: '#64748B',
                fontSize: 16,
                textAlign: 'center'
              }}
            >
              Error al obtener la encuesta
            </Box>
          </Box>
        )}
      </Box>
    </div>
  )
}

export default OrderInstallationSurvey
