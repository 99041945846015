import React, { useEffect } from 'react'
import { Route, Routes, Outlet, useLocation } from 'react-router-dom'
import Login from '../pages/Login/Login'
import Logout from '../pages/Logout/Logout'
import Home from '../pages/Home/Home'
import SetPassword from '../pages/SetPassword/SetPassword'
import ForgetPassword from '../pages/ForgetPassword/ForgetPassword'
import ServiceStatus from '../pages/ServiceStatus/ServiceStatus'
import ServiceScore from '../pages/ServiceScore/ServiceScore'
import Wifi from '../pages/Wifi/Wifi'
import WifiStatus from '../pages/Wifi/Status/WifiStatus'
import NetworkDevices from '../pages/Wifi/NetworkDevices/NetworkDevices'
import AdministratorLogin from 'components/shared/AdministratorLogin'
import Devices from '../pages/Wifi/Devices/Devices'
import Support from '../pages/Support/Support'
import RequireAuth from '../components/shared/RequireAuth'
import PersistLogin from '../components/shared/PersistLogin'
import PersistNetworkData from '../components/shared/PersistNetworkData'
import ErrorBoundary from '../components/shared/ErrorBoundary'
import useGoogleAnalytics from 'hooks/useGoogleAnalytics'
import BillingDataPage from 'pages/Billing/BillingDataPage'
import Payments from 'pages/Payments/Payments'
import Survey from '../pages/Survey/Survey'
import OrderInstallationSurvey from 'pages/Survey/Order/OrderInstallationSurvey'

const AppRoutes = () => {
  const { pathname } = useLocation()
  const { newPageView } = useGoogleAnalytics()
  useEffect(() => {
    newPageView(pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Routes>
      <Route path='login' element={<Login />}></Route>
      <Route path='forgetpassword' element={<ForgetPassword />}></Route>
      <Route path='setpassword' element={<Outlet />}>
        <Route path=':token' element={<SetPassword />} />
      </Route>
      <Route path='logout' element={<Logout />}></Route>
      <Route path='survey'>
        <Route path='order'>
          <Route
            path='installation'
            element={<OrderInstallationSurvey />}
          ></Route>
        </Route>
      </Route>

      <Route element={<AdministratorLogin />}>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<PersistNetworkData />}>
              <Route path='/' element={<Home />}></Route>
              <Route path='home' element={<Home />}></Route>
              <Route path='serviceStatus' element={<ServiceStatus />}></Route>
              <Route path='internetScore' element={<ServiceScore />}></Route>
              <Route path='wifi'>
                <Route
                  path='status'
                  element={<Wifi children={<WifiStatus />} />}
                ></Route>
                <Route
                  path='networkdevices'
                  element={<Wifi children={<NetworkDevices />} />}
                ></Route>
                <Route
                  path='devices'
                  element={<Wifi children={<Devices />} />}
                ></Route>
              </Route>
              <Route path='support' element={<Support />}></Route>
              <Route path='payments' element={<Payments />}></Route>
              <Route path='billing' element={<BillingDataPage />}></Route>
            </Route>
            <Route path='error' element={<ErrorBoundary />}></Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes
