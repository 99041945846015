import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReportProblem from './ReportProblem'
import { Grid, Button } from '@mui/material'
import unplugged from 'assets/images/unplugged.png'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const ErrorBoundary = () => {
  const navigate = useNavigate()
  return (
    <div className='errorContent'>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12}>
          <img
            className='errorContet-img'
            alt=''
            src={unplugged}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className='errorContet-title'>
            <h4>¡Lo lamentamos, algo salió mal!</h4>
          </div>
        </Grid>
        <Grid item xs={12}>
          <ReportProblem />
        </Grid>
        <Grid item xs={8}>
          <div className='errorContet-goback'>
            <Button
              fullWidth
              variant='outlined'
              onClick={() => navigate(-1)}
              startIcon={<KeyboardArrowLeftIcon />}
            >
              Regresar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ErrorBoundary
