import React from 'react'
import { Grid } from '@mui/material'
import useUserData from 'hooks/useUserData'

const MyUserData = () => {
  const { userData } = useUserData()
  return (
    <div className='homeContent-myUserData'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className='myUserData-title axtel-text'>Mis datos</h3>
        </Grid>
        <Grid item xs={12}>
          <h5 className='myUserData-subtitle axtel-text'>Nombre</h5>
          <h4 className='myUserData-name axtel-text'>{userData?.fullName}</h4>
          <h5
            className='myUserData-subtitle axtel-text'
            style={{ marginTop: '10px' }}
          >
            Correo electrónico
          </h5>
          <h4 className='myUserData-email axtel-text'>{userData?.email}</h4>
        </Grid>
      </Grid>
    </div>
  )
}

export default MyUserData
