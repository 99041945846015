/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Grid, Typography, TextField, Button } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import useLogin from './hooks/useLogin'
import Suspended from 'components/shared/Suspended'
import axtelLogo from 'assets/images/axtel-logo-blanco.png'

const Login = () => {
  const navigate = useNavigate()
  const { login, handleChange, handleLogin, isSubmitDisabled } = useLogin()
  let search = useLocation().search
  let isSuspended = new URLSearchParams(search).get('suspended')

  return (
    <div className='LoginContent axtel-background'>
      {isSuspended === 'true' ? <Suspended /> : null}
      <Grid
        container
        style={{ display: 'flex', justifyContent: 'center' }}
        spacing={1}
      >
        <Grid item xs={10} md={6} lg={6}>
          <img src={axtelLogo} alt='' style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={7} lg={7} style={{ textAlign: 'center' }}>
          <Typography
            variant='h4'
            style={{ color: 'white', marginBottom: '10px' }}
          >
            Portal Clientes
          </Typography>
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant='h6' className='' style={{ color: 'white' }}>
                Iniciar sesión
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                className='axtel-textfield-white'
                InputProps={{
                  style: {
                    color: 'white'
                  }
                }}
                id='Login-email'
                label='Email'
                name='email'
                variant='standard'
                value={login.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                InputProps={{
                  style: {
                    color: 'white'
                  }
                }}
                className='axtel-textfield-white'
                fullWidth
                id='Login-password'
                label='Contraseña'
                variant='standard'
                name='password'
                type='password'
                value={login.password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={10}>
              <Button
                fullWidth
                //variant='contained'
                className='axtel-button-full'
                type='submit'
                size='large'
                disabled={isSubmitDisabled()}
                onClick={handleLogin}
              >
                Ingresar
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Button
                fullWidth
                size='large'
                sx={{ color: 'white' }}
                // variant="standard"
                //className='axtel-button-outlined'
                onClick={() => navigate('/forgetpassword')}
              >
                Olvidé mi contraseña
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
