import { urls } from 'utils/urls'
import React, { useState } from 'react'
import useUserData from './useUserData'
import { privateAxiosFieldServices } from 'utils/axios'
import { setSessionItem } from 'utils/session'

const useFieldServices = () => {
  const [order, setOrder] = useState<any>({})
  const [orderRating, setOrderRating] = useState<any>({})

  const getOrder = async (orderId: string) => {
    //setGlobalLoading(true)
    try {
      const { data: response } = await privateAxiosFieldServices.get(
        `task/${orderId}`
      )

      setOrder({ success: true, order: response })
    } catch (error) {
      setOrder({ success: false })
    } finally {
      //setGlobalLoading(false)
    }
  }

  const updateOrderRating = async (
    orderId: string,
    ratingNumber: number,
    ratingComment?: string
  ) => {
    let body = ratingComment
      ? { orderId: orderId, rating: ratingNumber, comment: ratingComment }
      : { orderId: orderId, rating: ratingNumber }

    try {
      const { data: response } = await privateAxiosFieldServices.patch(
        `order/rating`,
        body
      )
      setOrderRating(response)
    } catch (error) {
      setOrderRating({ success: false })
    } finally {
      //setGlobalLoading(false)
    }
  }

  return {
    order,
    orderRating,
    getOrder,
    updateOrderRating
  }
}

export default useFieldServices
