import React, { ReactNode } from 'react'
import Container from '@mui/material/Container'
import BottomNavigator from './shared/BottomNavigation'
import useUserData from 'hooks/useUserData'
import _ from 'lodash'

const Layout = ({ children }: { children: ReactNode }) => {
  const { auth, networkData } = useUserData()
  return (
    <div className='AppLayout'>
      <Container sx={{ padding: '0 !important' }}>
        <main>{children}</main>
      </Container>
      {auth && <BottomNavigator />}
    </div>
  )
}

export default Layout
