/* eslint-disable react-hooks/exhaustive-deps */
//React
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//Icons
import { Grid, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

//Hooks
import useUserData from 'hooks/useUserData'
import usePaymentData from 'hooks/usePaymentData'
import useBilling from 'hooks/useBilling'
import usePaymentContext from 'hooks/usePaymentContext'
//Components
import PaymentMethod from './PaymentMethod/PaymentMethod'
import Subscription from './Subscription/Subscription'
import Invoices from './Invoices/Invoices'
import BillingData from './BillingData/BillingData'

import { isEmpty } from 'lodash'
import Suspended from './Suspended/Suspended'
import { getCustomerService } from 'utils/services/crm'

const Payments = () => {
  const navigate = useNavigate()
  const {
    setOptionMenuActive,
    isGlobalLoading,
    setGlobalLoading,
    userData,
    setUserData
  } = useUserData()
  const [isLoading, setIsLoading] = useState(true)
  const [logs, setLogs] = useState<any[]>([])
  const { billingCustomer } = useBilling()
  const { invoices, customer, subscription } = usePaymentContext()
  const { getSubscription, getInvoices, getCustomer, getCharges, getDebtLogs } =
    usePaymentData()

  useEffect(() => {
    setOptionMenuActive(2)
    setGlobalLoading(true)

    const getData = async () => {
      //setLoading(true)
      isGlobalLoading && setGlobalLoading(true)

      const getSubscriptionRequest = await getSubscription()
      const getCustomerRequest = await getCustomer()
      const getInvoicesRequest = await getInvoices()
      const getCustomerServiceRequest = await getCustomerService({
        query: userData.customerId,
        fields: 'status,statusDebt'
      })
      const getDebtLogsRequest = await getDebtLogs({
        customerId: userData.customerId,
        lastInvoice: true,
        validateInvoicePaid: true
      })

      //promises.push(getLastCharge())
      Promise.allSettled([
        getSubscriptionRequest,
        getCustomerRequest,
        getInvoicesRequest,
        getCustomerServiceRequest,
        getDebtLogsRequest
      ])
        .then(async (results) => {
          if (results[1].status === 'fulfilled') {
            let resultGetCustomer = results[1].value as any
            await getCharges({ stripeCustomerId: resultGetCustomer['id'] })
          }
          if (results[3].status === 'fulfilled') {
            let resultGetCustomer = results[3].value as any
            if (
              userData.status !== resultGetCustomer['status'] ||
              userData.statusDebt !== resultGetCustomer['statusDebt']
            ) {
              setUserData({
                ...userData,
                status: resultGetCustomer['status'],
                statusDebt: resultGetCustomer['statusDebt']
              })
            }
          }
          if (results[4].status === 'fulfilled') {
            if (results[4].value?.length > 0) {
              setLogs(results[4].value)
            }
          }
        })
        .finally(() => {
          setGlobalLoading(false)
          setIsLoading(false)
        })
    }
    getData()
  }, [])

  return (
    <div className='supportContent'>
      {isGlobalLoading && isLoading && <></>}
      {!isGlobalLoading && !isLoading && (
        <>
          <Grid
            container
            spacing={1}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item container xs justifyContent='center' alignItems='center'>
              <IconButton
                className='navigationButton axtel-icon-rounded'
                color='primary'
                aria-label='upload picture'
                component='label'
                onClick={() => navigate(-1)}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <h3 className='supportContent-title axtel-text'>Pagos</h3>
            </Grid>
            <Grid item xs></Grid>
          </Grid>

          <Grid container style={{ marginBottom: '20%' }}>
            {userData.statusDebt === 'SUSPENDED' && (
              <Grid xs={12}>
                <Suspended />
              </Grid>
            )}

            {!isEmpty(subscription) && (
              <Grid xs={12}>
                <Subscription data={subscription} logs={logs} />
              </Grid>
            )}

            {!isEmpty(customer) && (
              <Grid xs={12}>
                <PaymentMethod
                  refresData={getCustomer}
                  data={
                    customer?.invoice_settings?.default_payment_method?.card
                  }
                  collectionMethod={subscription?.collectionMethod}
                  customer={customer}
                />
              </Grid>
            )}

            <Grid xs={12}>
              <BillingData data={billingCustomer} />
            </Grid>

            {!isEmpty(invoices) && (
              <Grid xs={12}>
                <Invoices
                  invoices={invoices}
                  refreshInvoices={(limit: number) => {
                    getInvoices(limit)
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  )
}

export default Payments
