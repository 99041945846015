import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from 'hooks/usePrivateAxios'
import useUserData from 'hooks/useUserData'
import { notify } from 'utils/notify'
import { urls } from 'utils/urls'

interface UserLogin {
  email: string
  password: string
}

const useLogin = () => {
  const privateAxios = useAxiosPrivate()
  const { setGlobalLoading, setUserData } = useUserData()
  const navigate = useNavigate()
  const [login, setLogin] = useState<UserLogin>({
    email: '',
    password: ''
  })

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setLogin((prevEvent) => ({
      ...prevEvent,
      [e.target.name]:
        e.target.name === 'email'
          ? e.target.value.toLowerCase()
          : e.target.value
    }))
  }

  const handleLogin = async () => {
    try {
      setGlobalLoading(true)
      const validateLogin = await privateAxios.post(urls.customerLogin, {
        email: login.email,
        password: login.password
      })
      if (validateLogin.data.auth) {
        setUserData({
          customerId: validateLogin.data.customerId,
          customerType: validateLogin.data.customerType,
          auth: validateLogin.data.auth,
          email: validateLogin.data.email,
          firstName: validateLogin.data.firstName,
          lastName: validateLogin.data.lastName,
          fullName: validateLogin.data.fullName,
          offer: validateLogin.data.offer,
          currentOffer: validateLogin.data.currentOffer,
          network: validateLogin.data.network,
          status: validateLogin.data.status,
          statusDebt: validateLogin.data.statusDebt
        })
        if (
          validateLogin.data.statusDebt === 'SUSPENDED' ||
          validateLogin.data.statusDebt === 'LAST_PAYMENT_FAIL'
        ) {
          navigate('/payments')
        } else {
          navigate('/home')
        }
      } else {
        notify('error', 'Credenciales incorrectas')
      }
    } catch (error) {
      notify('error', 'No se pudo validar las credenciales')
    } finally {
      setGlobalLoading(false)
    }
  }

  const isSubmitDisabled = (): boolean => {
    return login.email === '' || login.password === ''
  }

  return { login, handleChange, handleLogin, isSubmitDisabled }
}

export default useLogin
