import { publicAxios } from '../utils/axios'
import useUserData from './useUserData'
import { urls } from 'utils/urls'

const useRefreshToken = () => {
  const { setUserData } = useUserData()
  //https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/
  const refreshToken = async () => {
    try {
      const res = await publicAxios.post(urls.refreshToken)
      setUserData(res.data)
    } catch (error) {
      setUserData({})
    }

    //return response.data.accessToken
  }
  return refreshToken
}

export default useRefreshToken
