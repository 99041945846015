import React from 'react'
import useUserData from './useUserData'
import usePaymentContext from './usePaymentContext'
import { privateAxiosPaynet } from 'utils/axios'
import { AxiosError } from 'axios'
import { getCustomerCharges } from 'utils/services/paynet'
//import LoadingButton from '@mui/lab/LoadingButton';

const usePaymentData = () => {
  const [cards, setCards] = React.useState([])

  const {
    setInvoices,
    setSubscription,
    setCustomerData,
    invoices,
    customer,
    charges,
    setCharges
  } = usePaymentContext()

  const { userData } = useUserData()

  const getSubscription = async () => {
    try {
      const { data: response } = await privateAxiosPaynet.get(
        `customer/${userData.customerId}/subscription`,
        {
          params: {
            fields:
              'id,nextPaymentDate,plan,upcomingInvoice,collectionMethod,latestInvoice'
          }
        }
      )
      setSubscription(response?.response)
    } catch (error) {
    } finally {
    }
  }

  const getCustomer = async () => {
    try {
      const { data: response } = await privateAxiosPaynet.get(`customer`, {
        params: {
          customer_id: userData.customerId,
          fields: 'id,invoice_settings'
        }
      })
      setCustomerData(response?.response)
      return response?.response
    } catch (error) {
    } finally {
    }
  }

  const getInvoices = async (limit: number = 3) => {
    try {
      const { data: response } = await privateAxiosPaynet.get(
        `customer/${userData.customerId}/invoices`,
        {
          params: {
            limit: limit
          }
        }
      )
      setInvoices(response?.response)
    } catch (error) {
    } finally {
    }
  }

  const getCharges = async ({
    stripeCustomerId
  }: {
    stripeCustomerId: string
  }) => {
    let charges = await getCustomerCharges({
      stripeCustomerId: stripeCustomerId,
      limit: 20
    })
    if (charges?.response?.length > 0) {
      setCharges(charges?.response)
    }
  }

  const retryPayment = async () => {
    try {
      const { data: response } = await privateAxiosPaynet.post(
        `customer/${customer.invoice_settings.default_payment_method.customer}/retry-payment`
      )
      if (response['success'] === false) {
        throw Error(response['message'])
      }
      return response
    } catch (error) {
      if (error instanceof AxiosError) {
        let errorData = error.response?.data
        if (errorData.message.includes('declined')) {
          throw Error('declined')
        } else {
          throw Error('error')
        }
      } else {
        throw Error(error.message)
      }
    } finally {
    }
  }

  const getDebtLogs = async ({
    customerId = null,
    lastInvoice = null,
    validateInvoicePaid = null
  }: any) => {
    try {
      const { data: result } = await privateAxiosPaynet.get(`debt/logs`, {
        params: {
          customerId,
          lastInvoice,
          validateInvoicePaid
        }
      })

      return result
    } catch (error) {
      console.error(error)
    }
  }

  return {
    getSubscription,
    getCustomer,
    getInvoices,
    retryPayment,
    getCharges,
    getDebtLogs,
    cards,
    invoices,
    charges
  }
}

export default usePaymentData
