import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import HomeIcon from '@mui/icons-material/Home'
import WifiIcon from '@mui/icons-material/Wifi'
import ConstructionIcon from '@mui/icons-material/Construction'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import useUserData from 'hooks/useUserData'
import _ from 'lodash'

const BottomNavigator = () => {
  //const [value, setValue] = React.useState(0)
  const navigate = useNavigate()
  const {
    optionMenuActive,
    setOptionMenuActive,
    setGlobalLoading,
    networkData
  } = useUserData()

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        elevation: 3
      }}
    >
      <BottomNavigation
        sx={{ height: '82px;' }}
        showLabels
        value={optionMenuActive}
        onChange={(event, newValue) => {
          if (newValue in [1, 2]) {
            setGlobalLoading(true)
          }
          setOptionMenuActive(newValue)
        }}
      >
        <BottomNavigationAction
          label='Inicio'
          onClick={() => navigate('/home')}
          icon={<HomeIcon />}
        />
        {!_.isEmpty(networkData) && (
          <BottomNavigationAction
            label='WiFi'
            icon={<WifiIcon />}
            onClick={() => navigate('/wifi/status')}
          />
        )}

        <BottomNavigationAction
          label='Pagos'
          icon={<AccountBalanceWalletOutlinedIcon />}
          onClick={() => navigate('/payments')}
        />
        <BottomNavigationAction
          label='Soporte'
          onClick={() => navigate('/support')}
          icon={<ConstructionIcon />}
        />
      </BottomNavigation>
    </Box>
  )
}

export default BottomNavigator
