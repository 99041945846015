import React from 'react'
import { TextField } from '@mui/material'
import { validateRFC, validateEmail } from 'utils/validations/form'
export default function BasicField({
  label,
  value,
  onChange,
  name,
  type,
  disabled = false,
  required = false,
  size = 'medium'
}: {
  label: string
  value: string
  onChange: any
  name: string
  type: string
  disabled?: boolean
  required?: boolean
  maxLength?: any
  size?: any
}) {
  const [error, setError] = React.useState<any>(false)
  const [errorMessage, setErrorMessage] = React.useState<any>('')

  const getErrors = () => {
    if (required && !Boolean(value)) {
      setError(true)
      setErrorMessage('Este campo es requerido')
      return
    }

    if (name === 'rfc' && !validateRFC(value)) {
      setError(true)
      setErrorMessage('RFC inválido')
      return
    }

    if (name === 'email' && !validateEmail(value)) {
      setError(true)
      setErrorMessage('Email inválido')
      return
    }

    setError(false)
    setErrorMessage('')
  }

  React.useEffect(() => {
    getErrors()
  }, [value])

  const handleChange = (e: any) => {
    onChange(e)
  }

  return (
    <>
      <TextField
        style={{ width: '100%' }}
        error={error}
        helperText={errorMessage}
        label={label}
        value={value}
        name={name}
        type={type}
        disabled={disabled}
        onChange={handleChange}
        required={required}
        size={size}
      />
    </>
  )
}
