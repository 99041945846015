import React from 'react'
import { Grid, Card, CardContent, Typography, Button } from '@mui/material'
import mastercard from '../../../assets/images/mastercard.png'
import visa from '../../../assets/images/visa_dark.png'
import amex from '../../../assets/images/american-express.png'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import UpdatePaymentMethodDialog from './UpdatePaymentMethod/UpdatePaymentMethodDialog'
import useStripeData from 'hooks/useStripeData'

export default function PaymentMethod({
  data,
  customer,
  collectionMethod
}: any) {
  const { createSetupIntent } = useStripeData()
  const [openDialog, setOpenDialog] = React.useState(false)

  const brandStyles: any = {
    mastercard: {
      background: '#282828',
      color: '#fff',
      margin: '3%',
      icon: (
        <img
          src={mastercard}
          alt='mastercard'
          width='50px'
          style={{ marginRight: 10 }}
        />
      )
    },
    visa: {
      background: '#02449e',
      color: '#fff',
      margin: '3%',
      icon: (
        <img src={visa} alt='visa' width='50px' style={{ marginRight: 10 }} />
      )
    },
    amex: {
      background: '#02449e',
      color: '#fff',
      margin: '3%',
      icon: (
        <img src={amex} alt='visa' width='50px' style={{ marginRight: 10 }} />
      )
    }
  }

  const handleUpdatePaymentMethod = async () => {
    const setupIntent = await createSetupIntent(customer?.id)
    setOpenDialog(true)
  }

  return (
    <div>
      <UpdatePaymentMethodDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        setOpen={setOpenDialog}
        customer={customer}
      />
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} lg={6}>
          <Card style={{ margin: 5 }} variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='caption' className='axtel-text'>
                    Método de pago
                  </Typography>
                </Grid>

                {collectionMethod === 'send_invoice' && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant='subtitle2'
                        className='axtel-text'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <AccountBalanceOutlinedIcon /> TRANSFERENCIA BANCARIA
                        (SPEI)
                      </Typography>
                    </Grid>
                  </>
                )}

                {collectionMethod === 'charge_automatically' && (
                  <>
                    <Grid item xs={6}>
                      <Typography
                        variant='subtitle2'
                        className='axtel-text'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {brandStyles[data?.brand]?.icon} XXXX {data?.last4}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography variant='subtitle2' className='axtel-text'>
                        Expira el: {data?.exp_month} / {data?.exp_year}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button
                        //variant='outlined'
                        className='axtel-button-outlined'
                        style={{ width: '100%' }}
                        onClick={handleUpdatePaymentMethod}
                      >
                        <Typography variant='caption'>
                          Cambiar método de pago
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
