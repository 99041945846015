import { urls } from 'utils/urls'
import React from 'react'
import useUserData from './useUserData'
import { notify } from 'utils/notify'
import { privateAxiosBilling } from 'utils/axios'

const useBilling = () => {
  const [billingCustomer, setBillingCustomer] = React.useState({})
  const { userData, setGlobalLoading } = useUserData()
  const [cfdi, setCFDI] = React.useState<any>({})

  React.useEffect(() => {
    const getData = async () => {
      const customer = await getBillingCustomer()
    }
    getData()
  }, [])

  const validateFiscalData = async (payload: any) => {
    try {
      const { data: response } = await privateAxiosBilling.post(
        'customer/validate-profile',
        payload
      )
      return response?.data
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getBillingCustomer = async () => {
    //setGlobalLoading(true)
    try {
      const { data: response } = await privateAxiosBilling.get(
        `customer/${userData.customerId}`
      )
      setBillingCustomer(response?.data)
    } catch (error) {
    } finally {
      //setGlobalLoading(false)
    }
  }

  const getCFDI = async (invoiceId: string) => {
    try {
      const { data: response } = await privateAxiosBilling.get(
        `invoice/cfdi/${invoiceId}`
      )
      setCFDI(response)
      return response
    } catch (error) {
      notify('error', 'No se puede descargar la factura')
      return {}
    } finally {
    }
  }

  const createCustomer = async (payload: any) => {
    try {
      const { data: response } = await privateAxiosBilling.post(
        urls.API_BILLING_createCustomer,
        payload
      )
      notify('success', 'Información guardada correctamente')
      return response
    } catch (error: any) {
      if (error?.response?.status === 409) {
        notify('error', 'Ya cuentas cuenta con información fiscal registrada')
      } else {
        notify('error', 'Ocurrió un error al guardar la información')
      }
      console.error(error.response.status)
    } finally {
    }
  }

  const updateBillingCustomer = async (payload: any) => {
    //setGlobalLoading(true)
    try {
      const { data: response } = await privateAxiosBilling.put(
        `customer/${userData.customerId}/update`,
        payload
      )
      notify('success', 'Información guardada correctamente')
      getBillingCustomer()
      return response
    } catch (error) {
    } finally {
      //setGlobalLoading(false)
    }
  }

  return {
    billingCustomer,
    cfdi,
    updateBillingCustomer,
    getBillingCustomer,
    createCustomer,
    validateFiscalData,
    getCFDI
  }
}

export default useBilling
